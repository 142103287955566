import * as actionTypes from "../actionTypes.js";

const initialState = {
  isAuthenticated: true,
  user: null,
  redirectUrl: null 
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOG_IN:
      localStorage.setItem("user", JSON.stringify(action.payload));
      action.payload.data.access_permission.map((item, index) => (
        localStorage.setItem(item.key, item.token)
      ))
      return { ...state, user: action.payload, isAuthenticated: true };
    case actionTypes.LOG_OUT:
      localStorage.clear();
      return initialState;
    case actionTypes.SET_REDIRECT_URL:
      return { ...state, redirectUrl: action.payload };
    default:
      return state;
  }
};

export default authReducer;
