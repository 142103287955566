import React from 'react';

import "./styles.scss";

const TextInput = ({label, id, name, onChange, type, placeHolder, value, icon, endIcon, error, errorMsg, disabled}) => {

  return (
    <>
    <div className="custom-input">
      <div className="input__icon">
        { !icon === false && (icon) }
      </div>
      <input 
        className={`input ${error && 'input-red'} ${disabled && 'input-disabled'}`}
        id={id} 
        type={type} 
        name={name} 
        onChange={onChange} 
        disabled={disabled ? true : false}
        placeholder={placeHolder} 
        value={value}
        autoComplete="off"
        />
      <label className='label' htmlFor={id}>{label}</label>
      
      {
        endIcon &&
        <div className="input__iconEnd">
          { !endIcon === false && (endIcon) }
        </div>
      }
    </div>
    {
      error && <div className="custom-input-erroMsg">{errorMsg}</div>
    }
    </>
  )
}

export default TextInput;



// return (
//   <>
//   <label htmlFor={id}>{label}</label>
//     <input
//       id={id}
//       type={type}
//       name={name}
//       value={value}
//       onChange={onChange}
//       placeholder={placeHolder}
//     />
//   </>
// )