import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

import { ButtonBase } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';

import logo from "../../assets/icon/logo-getout-normal.png";
import TextInput from '../../components/Inputs/TextInput';
// import GetoutIcon from "../../assets/images/getoutIcon.png";
import Footer from '../../components/Footer/footer';

import { 
  LogIn, 
  VerifyUser, 
  UserExists 
} from '../../redux/actions/authActions';

import * as actionTypes from "../../redux/actionTypes";

import styles from "../../global.scss";
import "./styles.scss";

const Data = { UserName: "", Password: "", Product: "travel" };

const AuthPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [formData , setFormData] = useState(Data);
  const state = useSelector(state => state.auth);
  const [tokenExists, setTokenExists] = useState(true);
  const [allowed, setAllowed] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [errorMsg, setErrorMsg] = useState("");

  const [defaultOpen , setDefaultOpen] = useState(1);


  useEffect(() => {
    // console.log("params", params);
    // console.log("location", location);
    var redirectUrl; 
    if(location.search === ""){
      // setting redirect url - default
      dispatch({ type: actionTypes.SET_REDIRECT_URL, payload: process.env.REACT_APP_DEFAULT_REDIRECT})
    }else{
      redirectUrl = location.search.split('=')[1];
      // console.log(redirectUrl);
      // setting redirect url
      dispatch({ type: actionTypes.SET_REDIRECT_URL, payload: redirectUrl})
    }
    
    var refresh_token = localStorage.getItem("refresh_token")
    if(refresh_token && redirectUrl){
      setTokenExists(true);
      if(localStorage.getItem("permissions") === null || localStorage.getItem("permissions") === undefined){
        localStorage.clear();
        sessionStorage.clear();
        setAllowed(true);
        navigate("/");
      }else{
        dispatch(VerifyUser({ RefreshToken : refresh_token })).then((res) => {
          var allow = checkUserAccess(redirectUrl, localStorage.getItem("permissions"));
          if(allow){
            setAllowed(true);
            var cypherUser = CryptoJS.AES.encrypt(JSON.stringify(JSON.parse(localStorage.getItem("user")).data.employee_info), process.env.REACT_APP_SECRET_KEY).toString();
            console.log(cypherUser)
            var cypherCompany = CryptoJS.AES.encrypt(JSON.stringify(JSON.parse(localStorage.getItem("user")).data.company_info), process.env.REACT_APP_SECRET_KEY).toString();
            console.log(cypherCompany)
            window.location.href = `
            ${redirectUrl}info?user=${encodeURIComponent(cypherUser)}&company=${encodeURIComponent(cypherCompany)}&access=${res.data.token}`;
            return null;
          }else {
            setAllowed(false);
          }
        });
      }
    } else {
      setTokenExists(false);
      // do nothing let user login
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // check last selected option
  useEffect(() => {
    if(localStorage.getItem("selected")){
      setDefaultOpen(JSON.parse(localStorage.getItem("selected")))
    }else{  
      setDefaultOpen(1);
    }
  }, []);
  

  const onSubmitHandler = () => {
    try {
      dispatch(LogIn(formData, navigate, setLoading)).then((res) => {
        console.log(res);      
        localStorage.setItem("permissions", res.data.access_permission)
        var allow = checkUserAccess(state.redirectUrl, res.data.access_permission);
        var redirect = res.data.employee_info.IsSuperAdmin === 1 
          ? 
            `${process.env.REACT_APP_ADMIN}/verify/` 
          : 
            selectOpeningApp(res.data.access_permission);
        console.log(allow);
        // if(allow){
          // setAllowed(true);
          if(res.error === false && res.status === "success"){
            if(res.data.access_token.user_verified === true){

              var cypherUser = CryptoJS.AES.encrypt(JSON.stringify(res.data.employee_info), process.env.REACT_APP_SECRET_KEY).toString();
              
              var cypherCompany = CryptoJS.AES.encrypt(JSON.stringify(res.data.company_info), process.env.REACT_APP_SECRET_KEY).toString();
              
              localStorage.setItem("refresh_token", res.data.refresh_token.token);
              window.location.href = `${redirect}info?user=${encodeURIComponent(cypherUser)}&company=${encodeURIComponent(cypherCompany)}&access=${res.data.access_token.token}`;
              return null;
            }
          }
        // }else {
        //   setAllowed(false);
        // }
      });
    }catch (error) {
      // console.log(error)
    }
  }

  useEffect(() => {
    if(location.search === ""){
      if( defaultOpen === 1){
        dispatch({ type: actionTypes.SET_REDIRECT_URL, payload: `${process.env.REACT_APP_CARD}/verify/`})
      }else if(defaultOpen === 2){
        dispatch({ type: actionTypes.SET_REDIRECT_URL, payload: `${process.env.REACT_APP_EXPENSE}/verify/`})
      }else if(defaultOpen === 3){
        dispatch({ type: actionTypes.SET_REDIRECT_URL, payload: `${process.env.REACT_APP_TRAVEL_POLICY}/verify/`})
      }else if(defaultOpen === 4){
        dispatch({ type: actionTypes.SET_REDIRECT_URL, payload: `${process.env.REACT_APP_BOOK}/verify/`})
      }else if(defaultOpen === 5){
        dispatch({ type: actionTypes.SET_REDIRECT_URL, payload: `${process.env.REACT_APP_ADMIN}/verify/`})
      }else {
        dispatch({ type: actionTypes.SET_REDIRECT_URL, payload: `${process.env.REACT_APP_DEFAULT_REDIRECT}/verify/`})
      }
    }
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOpen]);

  const bookSubmitHandler = () => {
    console.log(formRef.current.submit())
  }

  const onChangeHandler = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
    if(e.target.name === "UserName" && e.target.value.length > 5){
      dispatch(UserExists({UserName: e.target.value , Password: "", Product: "travel"})).then((res) => {
        console.log(res);
      })
    }
  }

  // ----------------------- access checker ---------------------------------
  const checkUserAccess = (route, permissions) => {
    var allow = false;
    console.log(route, permissions)

    // admin routes
    if(route.includes("expense-admin") && permissions.includes("EXP_ADMIN_APP")){
      allow = true;
    }else if(route.includes("card-admin") && permissions.includes("CRD_ADMIN_APP")){
      allow = true;
    }else if(route.includes("travel-admin") && permissions.includes("TRV_ADMIN_APP")){
      allow = true;
    }else if(route.includes("admin") && permissions.includes("GEN_ADMIN_APP")){
      allow = true;
    // non-admin routes 
    }else if(route.includes("expense") && permissions.includes("EXP_APP")){
      allow = true;
    }else if(route.includes("card") && permissions.includes("CRD_APP")){
      allow = true;
    }else if(route.includes("travel-policy") && permissions.includes("TRV_APP")){
      allow = true;
    // account routes
    }else if(route.includes("myaccount") || route.includes("profile")){
      allow = true;
    }
    allow = true;
    // }else if(route === "http://localhost:3000/verify/"){
    //   allow = true;
    // }else if(route === "http://localhost:3001/verify/"){
    //   allow = true;
    // }else if(route === "http://localhost:3002/verify/"){
    //   allow = true;
    // }else if(route === "http://localhost:4200/verify/"){
    //   allow = true;
    // }else {
    //   allow = false;
    // }

    return allow;
  } 

  const selectOpeningApp = (permissions) => {
    //  for redirect url
    var redirectUrl = "";
    if(permissions.includes("TRV_APP")){
      redirectUrl = `${process.env.REACT_APP_TRAVEL_POLICY}/verify/`;
    }else if(permissions.includes("CRD_APP")){
      redirectUrl = `${process.env.REACT_APP_CARD}/verify/`;
    }else if(permissions.includes("EXP_APP")){
      redirectUrl = `${process.env.REACT_APP_EXPENSE}/verify/`;
    }else{
      redirectUrl = `${process.env.REACT_APP_DEFAULT_REDIRECT}/verify/`;
    }

    return redirectUrl;
  }
  

  // https://getout.travel/wp-content/themes/wp-bootstrap-4/assets/images/Sequence01.mp4
  // <form action='https://booking.getout.travel/users/handlers/login.aspx' ref={formRef} method="post"> 
  return (
    <>
    {
      !allowed
      ?
      <div style={{ width: "100%", height: "100vh" }} className='flexCenterCenterColumn'>
        <div className="notPermit__content flexCenterCenterRow" style={{textAlign: "center", padding: "30px", fontWeight: "500", marginTop: "80px"}} >
          <ErrorOutlineIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/> <span>You do not have access permission for this product.</span> 
        </div>
        <span 
          onClick={() => {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/auth");
            setAllowed(true);
            setTokenExists(false);
          }} 
          style={{ textDecoration: "underline", fontWeight: "600", color: "blue", cursor: "pointer", marginTop: "80px"}}>
          Log out
        </span>
      </div>
      :
      tokenExists
      ?
      <div style={{ width: "100%", height: "100vh" }} className='flexCenterCenterColumn'>
        <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <img
          width={120}
          style={{position: "absolute", left: "calc(50% - 60px)", bottom: "22vh", }}
          src={logo}
          alt="" />
      </div>
      :
      <>
        <div>
          <div className='topBarContainer'>
            <div className='topBar'>
              <img
                width={120}
                // style={{marginTop: "30px"}}
                src={logo}
                alt="" />
            </div>
          
          </div>
          <div className='background'>
            <div className='video-background'>
              {/* <img
                // width={120}
                style={{ width: "20vw", opacity: 0.7}}
                src={GetoutIcon}
                alt="" /> */}
            </div>
            <div className='login__window'>
              <div>
                <h1 className="login__title">
                  Corporate Login
                </h1>
              </div>
              {
                // (defaultOpen === 4 && location.search === "")
                // &&
                <form action='https://booking.getout.travel/users/login.aspx' ref={formRef} method="post" style={{ display: "none" }}> 
                  <input id="user" type="text" name="user" value={formData.UserName}></input>
                  <input id="password" type="password" name="password" value={formData.Password}></input>
                  <input type="hidden" id="client" name="client" value="agency"></input>
                  <input type="hidden" id="returnURL" name="returnURL" value="/"></input>
                  <button type="submit">Login</button>
                </form> 
              }
              <div className='login__input'>
                <TextInput 
                  id="UserName"
                  label="Email *"
                  type="email" 
                  name="UserName" 
                  value={formData.UserName} 
                  onChange={onChangeHandler}
                  placeHolder="Email"
                  icon={<EmailIcon sx={{ color: styles["primary_color"]}}/>}
                  error={error}
                  errorMsg={errorMsg}
                  />
              </div>
              <div className='login__input'>
                <TextInput 
                  id="Password"
                  label="Password *"
                  type="password" 
                  name="Password" 
                  value={formData.Password} 
                  onChange={onChangeHandler}
                  placeHolder="Password"
                  icon={<VpnKeyIcon sx={{ color: styles["primary_color"]}}/>}
                  />
              </div>
              
              <a href="/auth/forgot-password" style={{ fontSize: "14px", margin: "10px", fontWeight: "600", color: "blue"}}>
                Forgot Password ?
              </a>

              <div>
                <div className='flexCenterCenterRow' style={{ fontSize: "14px", fontWeight: 600, color: "black", margin: "25px" }}>
                  By proceeding, you agree to Lusid's 
                    <a href='https://getout.travel/privacy-policy/' style={{ textDecoration: "none"}}>&nbsp;{`Privacy Policy`}&nbsp;</a> 
                    and 
                    <a href='https://getout.travel/terms-and-conditions/' style={{ textDecoration: "none"}}>&nbsp;T&Cs </a>.
                </div>
                <div className='flexCenterCenterRow' style={{marginTop: "25px", marginBottom: "40px"}}>
                  <div 
                    style={{borderRadius: "25px", cursor: "pointer", marginLeft: "10px", marginRight: "10px" }}
                    className='flexCenterCenterRow topbarMain__btn'
                    onClick={() =>  {setDefaultOpen(4);bookSubmitHandler(); localStorage.setItem("selected", 4)}}>
                      <ButtonBase sx={{padding: "5px 10px 5px 10px", fontFamily: "roboto", fontWeight: "600", width: "160px"}}>
                        <LoginRoundedIcon sx={{ color: styles["white_color"], marginRight: "10px"}} />
                        Booking
                      </ButtonBase>
                  </div>
                  <div 
                    style={{borderRadius: "25px", cursor: "pointer", marginLeft: "10px", marginRight: "10px" }}
                    className='flexCenterCenterRow topbarMain__btn'
                    onClick={() => {onSubmitHandler(); setDefaultOpen(6); localStorage.setItem("selected", 6)}}>
                      <ButtonBase sx={{padding: "5px 10px 5px 10px", fontFamily: "roboto", fontWeight: "600", width: "160px"}}>
                        <LoginRoundedIcon sx={{ color: styles["white_color"], marginRight: "10px"}} />
                        Management
                      </ButtonBase>
                  </div>
                </div>
              </div>

             
              {/* {
                location.pathname.split("/").length === 2
                &&
                <div style={{margin: "15px 5px 15px 10px", fontSize: "14px", fontWeight: "500"}} className='flexCenterCenterRow'>
                  <div style={{textAlign: "left", width: "140px"}}>
                    After login, open 
                  </div>
                  <div className='flexCenter_Row' style={{ marginLeft: "10px", width: "100%"}}>
                    <div>
                      <div className={`flexCenterCenterColumn ${ defaultOpen === 1 ? "menuItem__selected" : "menuItem" }`}
                          onClick={() => {setDefaultOpen(1); localStorage.setItem("selected", 1)}}> 
                          <img src={CardFIcon} alt="" className='customIcon'/>
                          Card
                        </div>
                    </div>
                    <div>
                        <div className={`flexCenterCenterColumn ${ defaultOpen === 2 ? "menuItem__selected" : "menuItem" }`}
                          onClick={() => {setDefaultOpen(2); localStorage.setItem("selected", 2)}}> 
                          <img src={ExpenseIcon} alt="" className='customIcon'/>
                          Expense
                        </div>
                    </div>
                    <div>
                       <div className={`flexCenterCenterColumn ${ defaultOpen === 3 ? "menuItem__selected" : "menuItem" }`}
                          onClick={() => {setDefaultOpen(3); localStorage.setItem("selected", 3)}}> 
                          <img src={PolicyIcon} alt="" className='customIcon'/>
                          Policy
                        </div>
                    </div>
                    <div >
                        <div className={`flexCenterCenterColumn ${ defaultOpen === 4 ? "menuItem__selected" : "menuItem" }`}
                          onClick={() => {setDefaultOpen(4); localStorage.setItem("selected", 4)}}>  
                          <img src={BookIcon} alt="" className='customIcon'/>
                          Book
                        </div>
                    </div>
                    <div>
                       <div className={`flexCenterCenterColumn ${ defaultOpen === 5 ? "menuItem__selected" : "menuItem" }`}
                          onClick={() => {setDefaultOpen(5); localStorage.setItem("selected", 5)}}> 
                          <img src={AdminIcon} alt="" className='customIcon'/>
                          Admin
                        </div>
                    </div>
                  </div>
                </div>
              } */}
              {/* <div style={{marginTop: "20px", marginBottom: "20px", marginRight: "22px"}}>
                <LoadingBtn onClick={(defaultOpen === 4 && location.search === "") ? bookSubmitHandler : onSubmitHandler} text={"Login"} loading={loading}/>
              </div> */}
              <div>
                <p style={{ fontSize: "14px", fontWeight: "500", margin: "10px", color:"black"}}>
                  Don't have an account? {" "}
                  <span 
                    onClick={() => navigate("/auth/sign-up")} 
                    style={{ textDecoration: "underline", fontWeight: "600", color: "blue", cursor: "pointer"}}>
                    Sign up for business account
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    }
    </>
  )
}

export default AuthPage