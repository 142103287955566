import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import ProtectedRoutes from "./protectedRoutes";
import PublicRoutes from "./publicRoutes";

import ErrorModal from "../components/Modals/ErrorModal";

import * as actionTypes from "../redux/actionTypes";

//routes
import AuthPage from "../views/authPage/AuthPage";
import ForgotPassword from "../views/authPage/ForgotPassword";
import CompanySignUp from "../views/authPage/CompanySignUp";
import ResetPassword from "../views/authPage/ResetPassword";

const MainRoutes = () => {
  const [err, setErr] = useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  return(
    <Router>
      <ErrorModal open={errorModalOpen} setOpen={setErrorModalOpen} error={err} />
      <Routes>
        {/* Protected Routes */}
        <Route path="/" element={<ProtectedRoutes />}>
          {/* <Route path="/" element={<Navigate replace to="home" />} /> */}
          <Route path="" element={<Navigate to="/auth" />} />
          <Route path="/logout/:id" exact element={<Logout />} />
        </Route>
  
        {/* Public Routes */}
        <Route path="/" element={<PublicRoutes />}>
          <Route path="/auth" exact element={<AuthPage />} />
          <Route path="/auth/:id" exact element={<AuthPage />} />
          <Route path="/redirect/:error" element={<HandleError setError={(msg) => setErr(msg)} openModal={() => setErrorModalOpen(true)}/>} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />
          <Route path="/auth/sign-up" element={<CompanySignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
      </Routes>
    </Router>
  )};


const Logout = () => { 
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    // console.log("location", location);

    var redirectUrl = location.search.split('=')[1];
    // console.log(redirectUrl);
    // setting redirect url
    dispatch({ type: actionTypes.SET_REDIRECT_URL, payload: redirectUrl})
    setTimeout(() => {
      navigate(`/auth/login?redirect_url=${redirectUrl}`);
    }, 100);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return(
    <div style={{ width: "100%", height: "100vh", backgroundColor: "white"}}>Logging Out</div>
  )
};

// /redirect/error?Error=asd%3AIncorrect password

const HandleError = ({setError, openModal}) => {
  const location = useLocation();
  const navigate = useNavigate();
  // console.log(location.pathname.split("/"));

  useEffect(() => {
    setTimeout(() => {
      navigate("/auth"); 
    },200); 
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location]);

  if(location.pathname.split("/")[2] === "error"){
    // console.log(decodeURI(location.search.split("=")[1].split("%3A")[1]))
    var msg = decodeURI(location.search.split("=")[1].split("%3A")[1])
    if(msg === "Incorrect password"){}
    setError(msg);
    openModal();
  }

  
}
  
export default MainRoutes;
  