/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TextInput from '../../components/Inputs/TextInput';
import LoadingBtn from '../../components/Buttons/LoadingBtn';
import logo from "../../assets/icon/logo-getout-normal.png";

import Button1 from "../../components/Buttons/Button1";

import { Button, Tooltip } from "@mui/material";

import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';

import { UserCheck } from '../../redux/actions/authActions';

// import GetoutIcon from "../../assets/images/getoutIcon.png";

import "./styles.scss";
import styles from "../../global.scss";
import Footer from '../../components/Footer/footer';

const Data = { Email : "" };

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData , setFormData] = useState(Data);

  const onChangeHandler = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  }
  
  const onSubmitHandler = () => {
    dispatch(UserCheck(formData, setLoading));
  }

  return(
    <>
      <div>
        <div style={{ width: "100%", display: "flex", justifyContent: "center"}}>
          <div className='topBar'>
            <img
              width={120}
              src={logo}
              alt="" />
          </div>
        
        </div>
        <div className='background'>
          <div className='video-background'>
              {/* <img
                // width={120}
                style={{ width: "20vw", height: "20vw", opacity: 0.7}}
                src={GetoutIcon}
                alt="" /> */}
            </div>
          <div className='login__window'>
            <div>
              <h1 className="login__title">
                Forgot Password 
              </h1>
            </div>
            <div className='login__input'>
              <TextInput 
                id="Email"
                label="Email *"
                type="email" 
                name="Email" 
                value={formData.Email} 
                onChange={onChangeHandler}
                placeHolder="Email"
                icon={<EmailIcon sx={{ color: styles["primary_color"]}}/>}
                // endIcon={<Tooltip title="Send OTP" onClick={onSubmitHandler}><SendIcon sx={{ color: styles["primary_color"]}}/></Tooltip>}
                />
            </div>
            <div className='flexCenter_Row'>
              <LoadingBtn onClick={() => navigate('/auth')} text={"Back to Login"} loading={loading}/>
              <Button1 onClick={onSubmitHandler} text={"Send Reset Link"} loading={loading} main={true}/>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  )
}

export default ForgotPassword