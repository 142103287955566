//ERRORS
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
//MESSAGE
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
//AUTH
export const LOG_IN = "LOG_IN";
export const TOKEN_FETCH = 'TOKEN_FETCH';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const LOG_OUT = "LOG_OUT";

export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';


// COMPANY SIGN UP
export const GET_CITIES = 'GET_CITIES';