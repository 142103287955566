import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
// import Navigation from "../components/Navigation/Navigation";

const useAuth = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoutes = (props) => {
  const [auth, setAuth] = useState(useAuth());
  const location = useLocation()
  const user = localStorage.getItem("user");
  
  useEffect(() => {
    user ? setAuth(true) : setAuth(false) 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location]);

  return auth ? (
    <>
      {<Outlet />}
    </>
  ) : (
    <Navigate to="/auth" />
  );
};

export default ProtectedRoutes;
