import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TextInput from '../../components/Inputs/TextInput';
import LoadingBtn from '../../components/Buttons/LoadingBtn';
import logo from "../../assets/icon/logo-getout-normal.png";

import Button1 from "../../components/Buttons/Button1";

import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';

import Footer from '../../components/Footer/footer';
// import GetoutIcon from "../../assets/images/getoutIcon.png";

import styles from "../../global.scss";
import "./styles.scss";
import { ForgetPasswordVerify } from '../../redux/actions/authActions';
import { toast } from 'react-toastify';

const Data = {
  Email: "",
  Password: "",
  ConfirmPassword: "" 
};

const ResetPassword = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData , setFormData] = useState(Data);

  const [btnText, setBtnText] = useState(".");

  useEffect(() => {
    console.log(location.search);
    let temp =  location.search.split("&");
    console.log(temp)
    
    try {
      setFormData({
        ...Data,
        Email: temp[0].split("email=")[1] || "",
      });

      setBtnText((temp.length > 2 && temp[2].includes("account_stage")) ? "Create" : "Reset");
    } catch (err){
      console.log("bad in redirect url");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHandler = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  }
  
  const onSubmitHandler = () => {
    let key = location.search.split("&")[1].includes("key=") ? location.search.split("&")[1].split("=")[1] : ""

    let obj = {
      Email: formData.Email,
      NewPassword: formData.Password,
      Key: key
    };
  
    console.log(obj)
  
    dispatch(ForgetPasswordVerify(obj, setLoading)).then(() => {
      navigate("/auth");
    });
  }

  return (
    <>
      <div>
        <div style={{ width: "100%", display: "flex", justifyContent: "center"}}>
          <div className='topBar'>
            <img
              width={120}
              src={logo}
              alt="" />
          </div>
        
        </div>
        <div className='background'>
          <div className='video-background'>
              {/* <img
                // width={120}
                style={{ width: "20vw", height: "20vw", opacity: 0.7}}
                src={GetoutIcon}
                alt="" /> */}
            </div>
          <div className='login__window'>
            <div>
              <h1 className="login__title">
                Reset Password 
              </h1>
            </div>
            <div className='login__input'>
              <TextInput 
                id="Email"
                label="Email *"
                type="email" 
                name="Email" 
                disabled={true}
                value={formData.Email} 
                onChange={onChangeHandler}
                placeHolder="Email"
                icon={<EmailIcon sx={{ color: styles["primary_color"]}}/>}
                />
            </div>
            <div className='login__input'>
              <TextInput 
                id="Email"
                label="Password *"
                type="password"
                name="Password" 
                value={formData.Password} 
                onChange={onChangeHandler}
                placeHolder="Email"
                icon={<KeyIcon sx={{ color: styles["primary_color"]}}/>}
                />
            </div>
            <div className='login__input'>
              <TextInput 
                id="Email"
                label="Confirm Password *"
                type="password"
                name="ConfirmPassword" 
                value={formData.ConfirmPassword} 
                onChange={onChangeHandler}
                placeHolder="Email"
                icon={<KeyIcon sx={{ color: styles["primary_color"]}}/>}
                />
            </div>
            <div className='flexCenter_Row'>
              <Button1 onClick={() => navigate('/auth')} text={"Back to login"} />
              <LoadingBtn onClick={onSubmitHandler} text={btnText} loading={loading} main={true}/>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  )
}

export default ResetPassword;