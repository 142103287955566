import React, { useEffect } from "react";

import {
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import Button1 from "../Buttons/Button1";

const ErrorModal = ({ open, setOpen, error }) => {
  const scroll = "paper";
  const descriptionElementRef = React.useRef(null);

  // ------------------ open & close ----------------------------  
  useEffect(() => {
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={open}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{ style: { borderRadius: 20 }}}
    >
      <DialogContent>
        <DialogContentText sx={{ whiteSpace: "pre-wrap" }} id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <div style={{color: "black", fontSize: 16, fontWeight: 500, paddingTop: "20px"}} >
            {
              error === "Incorrect password"
              &&
              (
                <>
                  <div className="flexCenterCenterRow">
                    {"The Password you entered is incorrect."}
                  </div>
                  <div className="flexCenterCenterRow">
                    {"Please try again."}
                  </div>
                </>
                
              )
            }
            <div className="flexCenterSBRow" style={{ margin: "auto", width: "125px", marginTop: "30px" }}>
             
              <Button1 text={"Close"} onClick={handleClose} main={true}/>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorModal;
