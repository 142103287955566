import React from 'react';
import "./styles.scss";

import { CircularProgress } from '@mui/material';
import styles from "../../global.scss";

const LoadingBtn = ({onClick, text, disabled, loading, main}) => {
  return (
    <button className={`
      ${ 
        loading 
        ?
          main 
          ?
            `loadingBtn--enabled loadingBtn_black ${disabled ? "loadingBtn_black--disabled" : ""} `
          :
            `loadingBtn--enabled loadingBtn ${disabled ? "loadingBtn--disabled" : ""} `
        :
          main 
          ?
            `loadingBtn_black ${disabled ? "loadingBtn_black--disabled" : ""} `
          :
            `loadingBtn ${disabled ? "loadingBtn--disabled" : ""} `
        }
        `}
        disabled={disabled} onClick={onClick}>
      {
        loading
        ?
        <CircularProgress size={"19px"} sx={{ color: main ? styles['white_color'] : styles['grey_icon']}}/>
        :
        text
      }
    </button>
  )
}

export default LoadingBtn;