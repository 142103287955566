import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

import AuthReducers from "./authReducers";
import CompanySignUpReducer from "./compSignUpReducers";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: AuthReducers,
  company: CompanySignUpReducer
});

export default persistReducer(persistConfig, rootReducer);