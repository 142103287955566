import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import styles from "../../global.scss";


export const LogIn = (formData, navigate, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  var response; 
  try {
    const { data } = await api.login(formData);
    response = data
    if (data.error === false) {
      dispatch({ type: actionTypes.LOG_IN, payload: data });
      toast.success(
        <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
          {/* <CheckIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/> */}
          Login Successful! Please wait while we prepare your dashboard.
        </div>
        )
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error(
        <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
          {/* <ErrorOutlineRoundedIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/> */}
          {data.message}
        </div>
        )
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
  return response;
};

export const VerifyUser = (formData) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  var response;
  try {
    const { data } = await api.refreshToken(formData);
    response = data;
    if (data.error === false) {
      // dispatch({ type: actionTypes.LOG_IN, payload: data });
      // console.log(data);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
  }
  return response;
};

export const UserCheck = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data } = await api.userExists(formData);
    // console.log(data)
    if (data.status === 'success') {
      if(data.data.UserType === "not exists"){
        // user doesnt exist
        toast.error(
          <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
            {/* <ErrorOutlineRoundedIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/> */}
            User does not exists
          </div>
          )
      }else{
        // call forgot pwd
        // eslint-disable-next-line no-unused-vars
        const { forgot } = await api.forgotPassword(formData);
        // console.log(forgot)
        toast.success(
          <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
            {/* <CheckIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/> */}
            We have sent you an email containing link to reset your password. Please check your spam folder too.
          </div>
          )
      }
    }else{
      toast.error(
        <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
          {/* <ErrorOutlineRoundedIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/> */}
          {data.message}
        </div>
        )
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};


export const UserExists = (formData) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  var res = false;
  try {
    const { data } = await api.userExists(formData);
    // console.log(data)
    if(data.status === "success" && data.message === "user type detected"){
      if(data.data.UserType === "corporate") {
        res = true;
      }
    }else{
      toast.error(
        <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
          {/* <ErrorOutlineRoundedIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/> */}
          Something went wrong.
        </div>
        )
    }

  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
  }
  return res;
};


export const ForgetPasswordVerify = (formData) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  try {
    const { data } = await api.forgetPasswordVerify(formData);
    if(data.error === true){
      toast.error(data?.message);
    }else{
      toast.success(data?.message);
    }
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
  }
};
