import axios from "axios";

const API = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/`,
});

const API2 = axios.create({
  baseURL: `https://partnership.getout.travel/api/`,
});


API.interceptors.request.use((req) => {
  if (localStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("user"))?.data?.access_token?.token}`;
  }
  return req;
});

export const login = (form) => API.post("auth/v2/auth/login/v1", form);
export const userExists = (form) => API.post('auth/user-exists', form);
export const forgotPassword = (form) => API.post('auth/forget-password', form); 
export const forgetPasswordVerify = (form) => API.post(`auth/forget-password-verify`, form);
export const refreshToken = (form) => API.post('auth/v2/auth/get-access-token', form);

// company signup routes
export const getCities = (location) => API.get(`management/v1/search/city-list?CityLocation=${location}`);

export const sendOTP = (number) => API2.get(`sms/send-otp?mobile=${number}`);
export const verifyOTP = (token, otp, number) => API2.get(`sms/verify-otp?token=${token}&otp=${otp}&mobile=${number}`);

export const companyRegister = (form) => API.post(`auth/company-register`, form);

// user Exists


// page 1
// 1. company name - check the name is proper (length > 6)
// 2. street address - check the name is proper (length > 6)
// 3. enter city - call getCitiesAPI and show select

// page 1 - extended
// 3.1 ask for 

// page 2
// 4. first name - 
// 5. last name- 
// 6. country code -
// 7. mobile number - res{
//     "message": "SMS Sended",
//     "status": "success",
//     "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvdHAiOjE1NDE0OCwidHJpZ2dlcmVkX2J5IjoiOTE5MTY2ODUzNjE4IiwiaWF0IjoxNjY4NDMwMDYxLCJleHAiOjE2Njg0MzA2NjF9.TZ3-GmfUrf72ks4oZeR3lz-I4bi7iw-BGHQ11qWe8oY"
// }
// 7.1 - show OTP field - and also a timer for 60 seconds to resendOTP
// 7.2 - verify - call verifyOTPAPI with - token, otp, number
// {
//   "status": "success",
//   "message": "otp verification success",
//   "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0cmlnZ2VyZWRfYnkiOiI5MTkxNjY4NTM2MTgiLCJpYXQiOjE2Njg0MzA0MjksImV4cCI6MTY3MjAzMDQyOX0.M3LGvwVxM8S4jp5R3dKTIgbW3T3pgpPOf83Wg4WMYbQ"
// }
// 8. work email id - call userExistsAPI - and dont always for same email address.

// 9. Submit - post form -https://getout.travel/wp-content/themes/wp-bootstrap-4/forms/agancyRegistration.php?

// var res = {
//   "company_name": "test company 1",
//   "address_line_1":"test street",
//   "city":"San Diego",
//   "state":"California",
//   "zip_postal_code":"32100",
//   "country":"USA",
//   "gstin":"21-8515242",
//   "first_name":"Nitin",
//   "last_name":"Singh",
//   "country_code":"91",
//   "send_mobile":"9166853618",
//   "token_mobile":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0cmlnZ2VyZWRfYnkiOiI5MTkxNjY4NTM2MTgiLCJpYXQiOjE2Njg0MzA0MjksImV4cCI6MTY3MjAzMDQyOX0.M3LGvwVxM8S4jp5R3dKTIgbW3T3pgpPOf83Wg4WMYbQ",
//   "send_email":"nitintest1@gmail.com",
//   "password":"f7bbtefl",
//   "CityDistrictID":"43218",
//   "CitySatateID":"43742",
//   "CountryID":"41744",
//   "defaultCountryID":"236"
// }

// flag - https://getout.travel/wp-content/themes/wp-bootstrap-4/assets/flags/japan.gif