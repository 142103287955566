import React from 'react';
import "./styles.scss";

const Button1 = ({onClick, text, disabled, main}) => {
  return (
    <button className={`
    ${main 
        ? 
        `${disabled ? "button1_black--disabled" : ""} button1_black `
        : 
        `${disabled ? "button1--disabled" : ""} button1 ` 
      }
      `} 
      disabled={disabled} 
      onClick={onClick}>
        {text}
      </button>
  )
}

export default Button1;