/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TextInput from '../../components/Inputs/TextInput';
import LoadingBtn from '../../components/Buttons/LoadingBtn';
import logo from "../../assets/icon/logo-getout-normal.png";

import Button1 from "../../components/Buttons/Button1";

import { Button, CircularProgress, Tooltip } from "@mui/material";

import EmailIcon from '@mui/icons-material/Email';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SignpostIcon from '@mui/icons-material/Signpost';
import FlagIcon from '@mui/icons-material/Flag';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import PinIcon from '@mui/icons-material/Pin';
import PinDropIcon from '@mui/icons-material/PinDrop';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

import { UserCheck, UserExists } from '../../redux/actions/authActions';
import { GetCities, RegisterCompany, SendOTP, VerifyOTP } from '../../redux/actions/compSignUpActions';

// import GetoutIcon from "../../assets/images/getoutIcon.png";

import "./styles.scss";
import styles from "../../global.scss";
import Footer from '../../components/Footer/footer';

import { useTimer } from 'react-timer-hook';

import _ from 'lodash';

import Highlighter from "react-highlight-words";


const Data = { 
  company_name : "",
  address_line_1: "",

  city: "",
  district: "",
  state: "",
  zip_postal_code: "",
  country: "",
  CityDistrictID: "",
  CitySatateID: "",
  CountryID: "",
  defaultCountryID: "236",
  gstin: "",

  first_name: "",
  last_name: "",
  country_code: "91",
  send_mobile: "",

  token_mobile: "",
  send_email: "",
  password: "",

};

const CompanySignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(state => state.company);
  const [loading, setLoading] = useState(false);
  const [formData , setFormData] = useState("");
  const [hasCityName, setHasCityName] = useState(false);
  const [page, setPage] = useState(-1);
  const [enableSave, setEnableSave] = useState(false);
  const [enableFinalSave, setEnableFinalSave] = useState(false);
  const [sentOTP, setSentOTP] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const [errors, setErrors] = useState({});
  const [errorMsg, setErroMsg] = useState({});

  const [cityList, setCityList] = useState([]);

  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");
  const [resendOtp, setResendOtp] = useState(false);

  const [citySelected, setCitySelected] = useState(false);

  const [otpVerified, setOtpVerified] = useState(false);

  useEffect(() => {
    setPage(1);
    setFormData(Data);
  }, []);

  const onChangeHandler = (e) => {
    if(e.target.name === "gstin" && formData.country === "USA"){
      var newString = e.target.value;
      if(e.target.value.length > 2 && !e.target.value.includes("-")) newString = e.target.value.slice(0, 2) + "-" + e.target.value.slice(2);
      setFormData({...formData, [e.target.name]: newString});
    }else{
      var form =  _.cloneDeep(formData);
      setFormData({...form, [e.target.name]: e.target.value});
    }
    var tempErr = _.cloneDeep(errors);
    // business legal name
    if(e.target.name === "company_name" && e.target.value.length > 2){
      if(e.target.value.length < 4){
        tempErr = { ...tempErr, company_name: "Business Legal name is required."};
      }else{
        delete tempErr.company_name;
      }
    }
 
    // entering city name
    if(e.target.name === "city" && e.target.value.length > 2){
      dispatch(GetCities(e.target.value, setLoading))
    }
    // phone number
    if(e.target.name === "send_mobile" && e.target.value.length > 0){
      if(e.target.value.length === 10){
        delete tempErr.send_mobile;
      }else{
        tempErr = { ...tempErr, send_mobile: "Enter a valid number."};
      }
    }

    // phone number
    if(e.target.name === "send_email" && e.target.value.length > 2){
      dispatch(UserExists({ Email : e.target.value})).then((res) => {
        // console.log(res);
        if(res){
          tempErr = { ...tempErr, send_email: "This username already exists."};
        }else{
          delete tempErr.send_email;
        }
        setErrors(tempErr);
        if(Object.keys(tempErr).length === 0){
          setEnableSave(true)
        }else{
          setEnableSave(false)
        }
      });
    }

    // zip code
    if(e.target.name === "zip_postal_code" && e.target.value.length > 0){
      if(formData.country === "India"){
        if(e.target.value.length === 6){
          delete tempErr.zip_postal_code;
        }else{
          tempErr = { ...tempErr, zip_postal_code: "Enter a valid pin code."};
        }
      }else{
        if(e.target.value.length === 5){
          delete tempErr.zip_postal_code;
        }else{
          tempErr = { ...tempErr, zip_postal_code: "Enter a valid pin code."};
        }
      }
    }

    // zip code
    if(e.target.name === "zip_postal_code" && e.target.value.length > 0){
      if(formData.country === "India"){
        if(e.target.value.length === 6){
          delete tempErr.zip_postal_code;
        }else{
          tempErr = { ...tempErr, zip_postal_code: "Enter a valid pin code."};
        }
      }else{
        if(e.target.value.length === 5){
          delete tempErr.zip_postal_code;
        }else{
          tempErr = { ...tempErr, zip_postal_code: "Enter a valid pin code."};
        }
      }
    }

    // gstIn
    if(e.target.name === "gstin" && e.target.value.length > 0){
      if(formData.country === "India"){
        if(e.target.value.match('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$')){
          delete tempErr.gstin;
        }else{
          tempErr = { ...tempErr, gstin: "Enter a valid GSTIN."};
        }
      }else if(formData.country === "USA"){
        if(e.target.value.match(/^(0[1-9]|[1-9]\d)-\d{7}$/)){
          delete tempErr.gstin;
        }else{
          tempErr = { ...tempErr, gstin: "Enter a valid EIN."};
        }
      }else{
        if(e.target.value.length > 25) {
          tempErr = { ...tempErr, gstin: "Enter a valid Tax registration number."};
        }else{
          delete tempErr.gstin;
        }
      }
    }else{
      delete tempErr.gstin;
    }

    // first name and last name
    if(e.target.name === "first_name"){
      if(e.target.value.length > 3){
        delete tempErr.first_name;
      }else{
        tempErr = { ...tempErr, first_name: "Please enter first name."};
      }
    }
    if(e.target.name === "last_name"){
      if(e.target.value.length > 3){
        delete tempErr.last_name;
      }else{
        tempErr = { ...tempErr, last_name: "Please enter last name."};
      }
    }


    setErrors(tempErr);
    if(Object.keys(tempErr).length === 0){
      setEnableSave(true)
    }else{
      if (page === 1) setEnableSave(false)
    }
  }
  
  const onSubmitHandler = () => {
    // dispatch(UserCheck(formData, setLoading));
    var tempErr = errors;
    if(formData.first_name.length === 0){
      tempErr = { ...tempErr, first_name: "Please enter first name. *"};
    }else{
      delete tempErr.first_name;
    }   
    if(formData.last_name.length === 0){
      tempErr = { ...tempErr, last_name: "Please enter last name. *"};
    }else{
      delete tempErr.last_name;
    }
    if(formData.send_email.length === 0){
      tempErr = { ...tempErr, send_email: "Please enter email id. *"};
    }else{
      delete tempErr.send_email;
    }
    if(formData.send_mobile.length === 0){
      tempErr = { ...tempErr, send_mobile: "Please enter phone no. *"};
    }else{
      delete tempErr.send_mobile;
    }
   
    setErrors(tempErr);
    if(Object.keys(tempErr).length === 0){
      setEnableSave(true);
    }

    // console.log(formData)
    var obj = {
      BrandName: formData.company_name,
      LegalName: formData.company_name,
      GSTIN: formData.gstin,
      Email: formData.send_email,
      LoginId: formData.company_name,
      FirstName: formData.first_name,
      LastName: formData.last_name,
      FullAddress: formData.address_line_1+", "+formData.city +"-"+formData.zip_postal_code,
      DefaultCountry: "91",
      CategoryId: "119",
      AgencyGroupId: formData.country === "India" ? "35": "46",
      PanNumber: "",
      Market: "INDIA",
      Currency: formData.country === "India" ? "INR": "USD",
      Mobile: formData.send_mobile,
      TaxId: formData.country === "India" ? formData.gstin.substring(2, 11) : formData.gstin,
      City: formData.district,
      CityZip: formData.zip_postal_code,
      State: formData.state,
      Country: formData.country,
      Grade: "Grade",
      Password: (Math.random() + 1).toString(36).substring(2)
    }
    // console.log(obj)
    dispatch(RegisterCompany(obj, setLoading)).then((res) => {
      // console.log(res);
      if(res.status === "success"){
        setSubmitted(true);
      }
    })
  }

  const sendOTPhandler = () => {
    if(resendOtp){
      setResendOtp(false);
      setSentOTP(false);
    } 
    if(formData.send_mobile.length === 10){
      // console.log(formData.country_code ? formData.country_code+formData.send_mobile : "91"+formData.send_mobile)
      var number = formData.country_code ? formData.country_code+formData.send_mobile : "91"+formData.send_mobile
      dispatch(SendOTP(number)).then((res) => {
        if(res.status === "success"){
          setOtp("");
          setResendOtp(false);
          // console.log(res);
          setSentOTP(true);
          setToken(res.token);
        }
      })
    }else{
      setErrors({ send_mobile: "Enter a valid number."});
      setEnableSave(false);
    }
    
  }

  const handleVerifyOtp = () => {
    dispatch(VerifyOTP(token, otp, formData.send_mobile)).then((res) => {
      // console.log(res)
      if(res.status === "success"){
        setSentOTP(false);
        setResendOtp(false);
        setToken(res.token);
        setEnableFinalSave(true);
        setOtpVerified(true);
      }
    })
  }

  useEffect(() => {
    try {
      if(state){
        setCityList(state.cityList || []);
      } console.log(cityList)
    } catch(err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  const handleSelectCity = (item) => {
    // console.log(item);
    setCitySelected(true);
    setFormData({ 
      ...formData,
      city: `${item.CityDistrict}, ${item.StateCity}, ${item.State}, ${item.Country}`,
      state: item.StateCity,
      district: item.CityDistrict,
      country: item.Country,
      CityDistrictID: item.CityDistrictId,
      CitySatateID: item.StateCityId,
      CountryID: item.CountryId
    })
  }


  const pageSwitchHandler = () => {
    // check if all fields are filled
    var tempErr = errors;
    if(citySelected){
      delete tempErr.city;
    }else{
      tempErr = { ...tempErr, city: "Please enter city name. *"};
    }   
    if(formData.zip_postal_code.length === 0){
      tempErr = { ...tempErr, zip_postal_code: "Please enter postal code. *"};
    }else{
      delete tempErr.city;
    }
    if(formData.gstin.length === 0){
      tempErr = { ...tempErr, gstin: "Please enter required number. *"};
    }else{
      delete tempErr.gstin;
    }
   
   
    setErrors(tempErr);
    if(Object.keys(tempErr).length === 0){
      setEnableSave(true);
      setPage(2);
      // console.log(formData);
    }
  }

  return(
    <>
      <div>
        <div style={{ width: "100%", display: "flex", justifyContent: "center"}}>
          <div className='topBar'>
            <img
              width={120}
              src={logo}
              alt="" />
          </div>
        
        </div>
        <div className='background'>
          <div className='video-background'>
              {/* <img
                // width={120}
                style={{ width: "20vw", height: "20vw", opacity: 0.7}}
                src={GetoutIcon}
                alt="" /> */}
            </div>
          <div className='login__window'>
            <div>
              <h1 className="login__title">
                Company Details
              </h1>
            </div>

            {
              page === 1
              ?
              <>
                <div className='login__input'>
                  <TextInput 
                    label="Enter Business Legal name *"
                    name="company_name" 
                    value={formData.company_name} 
                    onChange={onChangeHandler}
                    icon={<ApartmentIcon sx={{ color: styles["primary_color"]}}/>}
                    error={errors.company_name}
                    errorMsg={errors.company_name}
                    />
                </div>
                <div className='login__input'>
                  <TextInput 
                    label="Enter Street address *"
                    name="address_line_1" 
                    value={formData.address_line_1} 
                    onChange={onChangeHandler}
                    icon={<SignpostIcon sx={{ color: styles["primary_color"]}}/>}
                    error={errors.address_line_1}
                    errorMsg={errors.address_line_1}
                    />
                </div>
                <div className='login__input' style={{position: "relative"}}>
                  <TextInput 
                    label="Start entering city *"
                    name="city"  
                    value={formData.city} 
                    onChange={onChangeHandler}
                    icon={<PinDropIcon sx={{ color: styles["primary_color"]}}/>}
                    error={errors.city}
                    errorMsg={errors.city}
                    endIcon={citySelected && <CloseIcon sx={{ color: styles["primary_color"]}} 
                      onClick={() => {
                        setFormData({...formData, city: ""});
                        setCitySelected(false);
                      }}/>}
                    />
                  {
                    formData.city.length > 0 && !citySelected
                    &&
                    <div className='city__dropDown'>
                      {
                        loading
                        ?
                        <div style={{ width: "100%", height: "100%"}} className="flexCenterCenterRow">
                          <CircularProgress />
                        </div>
                        :
                        cityList.map((item, index) => (
                          <div className='city__dropDown--item' key={index}>
                            <Highlighter
                                onClick={() => handleSelectCity(item)}
                                highlightClassName="city__dropDown--highlight"
                                searchWords={[formData.city]}
                                autoEscape={true}
                                textToHighlight={`${item.CityDistrict}, ${item.StateCity}, ${item.Country}`}
                              />
                            {/* {item.CityDistrict}, {item.StateCity}, {item.State}, {item.Country} */}
                          </div>
                        ))
                      }
                    </div>
                  }
                </div>
                {
                  citySelected
                  &&
                  <>
                    <div className='login__input'>
                      <TextInput 
                        label="State *"
                        name="state"  
                        disabled={true}
                        value={formData.state} 
                        onChange={onChangeHandler}
                        icon={<ApartmentIcon sx={{ color: styles["grey_icon"]}}/>}
                        error={errors.state}
                        errorMsg={errors.state}
                        />
                    </div>
                    <div className='login__input'>
                      <TextInput 
                        label="Enter Postal code *"
                        name="zip_postal_code"  
                        value={formData.zip_postal_code} 
                        onChange={onChangeHandler}
                        type="number"
                        icon={<ApartmentIcon sx={{ color: styles["primary_color"]}}/>}
                        error={errors.zip_postal_code}
                        errorMsg={errors.zip_postal_code}
                        />
                    </div>
                    <div className='login__input'>
                      <TextInput 
                        label="Country *"
                        name="country"  
                        disabled={true}
                        value={formData.country} 
                        onChange={onChangeHandler}
                        icon={<ApartmentIcon sx={{ color: styles["grey_icon"]}}/>}
                        error={errors.country}
                        errorMsg={errors.country}
                        />
                    </div>
                    <div className='login__input'>
                      <TextInput 
                        label={formData.country === "USA" ? "Enter EIN *" : formData.country === "India" ? "Enter GSTIN *" : "Enter Tax Registration No. *"}
                        name="gstin"  
                        value={formData.gstin} 
                        onChange={onChangeHandler}
                        icon={<ApartmentIcon sx={{ color: styles["primary_color"]}}/>}
                        error={errors.gstin}
                        errorMsg={errors.gstin}
                        />
                    </div>
                  </>
                }
                <div className='flexCenter_Row'>
                  <LoadingBtn onClick={() => navigate("/auth")} text={"Back to Login"} loading={loading}/>
                  <Button1 onClick={() => pageSwitchHandler()} text={"Next"} loading={loading} main={true} disabled={!enableSave}/>
                </div>
              </>
              :
                <>
                  <div className='login__input'>
                    <TextInput 
                      id="first name"
                      label="First name *"
                      name="first_name" 
                      value={formData.first_name} 
                      onChange={onChangeHandler}
                      icon={<PersonIcon sx={{ color: styles["primary_color"]}}/>}
                      error={errors.first_name}
                      errorMsg={errors.first_name}
                      />
                  </div>
                  <div className='login__input'>
                    <TextInput 
                      id="last name"
                      label="Last name *"
                      name="last_name" 
                      value={formData.last_name} 
                      defaultValue={""}
                      onChange={onChangeHandler}
                      icon={<GroupsIcon sx={{ color: styles["primary_color"]}}/>}
                      error={errors.last_name}
                      errorMsg={errors.last_name}
                      />
                  </div>
                  <div className='login__input flexCenterSBRow'>
                      <select name="country_code" id="country_code" className="select-input" onChange={onChangeHandler}   
                        value={formData.country_code} 
                        defaultValue={"91"} 
                        >
                        <option>Country Code*</option>
                        <option value="93">Afghanistan (93)</option>
                        <option value="355">Albania (355)</option>
                        <option value="213">Algeria (213)</option>
                        <option value="1684">American Samoa (1684)</option>
                        <option value="376">Andorra (376)</option>
                        <option value="244">Angola (244)</option>
                        <option value="1264">Anguilla (1264)</option>
                        <option value="1268">Antigua and Barbuda (1268)</option>
                        <option value="54">Argentina (54)</option>
                        <option value="374">Armenia (374)</option>
                        <option value="297">Aruba (297)</option>
                        <option value="61">Australia (61)</option>
                        <option value="43">Austria (43)</option>
                        <option value="994">Azerbaijan (994)</option>
                        <option value="1242">Bahamas (1242)</option>
                        <option value="973">Bahrain (973)</option>
                        <option value="880">Bangladesh (880)</option>
                        <option value="1246">Barbados (1246)</option>
                        <option value="375">Belarus (375)</option>
                        <option value="32">Belgium (32)</option>
                        <option value="501">Belize (501)</option>
                        <option value="229">Benin (229)</option>
                        <option value="1441">Bermuda (1441)</option>
                        <option value="975">Bhutan (975)</option>
                        <option value="591">Bolivia (591)</option>
                        <option value="387">Bosnia and Herzegovina (387)</option>
                        <option value="267">Botswana (267)</option>
                        <option value="55">Brazil (55)</option>
                        <option value="246">British Indian Ocean Territory (246)</option>
                        <option value="359">Bulgaria (359)</option>
                        <option value="226">Burkina Faso (226)</option>
                        <option value="257">Burundi (257)</option>
                        <option value="855">Cambodia (855)</option>
                        <option value="237">Cameroon (237)</option>
                        <option value="1">Canada (1)</option>
                        <option value="1345">Cayman Islands (1345)</option>
                        <option value="236">Central African Republic (236)</option>
                        <option value="235">Chad (235)</option>
                        <option value="56">Chile (56)</option>
                        <option value="86">China (86)</option>
                        <option value="61">Christmas Island (61)</option>
                        <option value="61">Cocos (Keeling) Islands (61)</option>
                        <option value="57">Colombia (57)</option>
                        <option value="269">Comoros (269)</option>
                        <option value="242">Congo (242)</option>
                        <option value="682">Cook Islands (682)</option>
                        <option value="506">Costa Rica (506)</option>
                        <option value="385">Croatia (385)</option>
                        <option value="53">Cuba (53)</option>
                        <option value="357">Cyprus (357)</option>
                        <option value="420">Czech Republic (420)</option>
                        <option value="45">Denmark (45)</option>
                        <option value="253">Djibouti (253)</option>
                        <option value="1767">Dominica (1767)</option>
                        <option value="1849">Dominican Republic (1849)</option>
                        <option value="670">East Timor (670)</option>
                        <option value="593">Ecuador (593)</option>
                        <option value="20">Egypt (20)</option>
                        <option value="503">El Salvador (503)</option>
                        <option value="240">Equatorial Guinea (240)</option>
                        <option value="291">Eritrea (291)</option>
                        <option value="372">Estonia (372)</option>
                        <option value="251">Ethiopia (251)</option>
                        <option value="500">Falkland Islands (500)</option>
                        <option value="298">Faroe Islands (298)</option>
                        <option value="358">Finland (358)</option>
                        <option value="33">France (33)</option>
                        <option value="594">French Guiana (594)</option>
                        <option value="689">French Polynesia (689)</option>
                        <option value="241">Gabon (241)</option>
                        <option value="220">Gambia (220)</option>
                        <option value="995">Georgia (995)</option>
                        <option value="49">Germany (49)</option>
                        <option value="233">Ghana (233)</option>
                        <option value="350">Gibraltar (350)</option>
                        <option value="30">Greece (30)</option>
                        <option value="299">Greenland (299)</option>
                        <option value="1473">Grenada (1473)</option>
                        <option value="590">Guadeloupe (590)</option>
                        <option value="1671">Guam (1671)</option>
                        <option value="502">Guatemala (502)</option>
                        <option value="224">Guinea (224)</option>
                        <option value="245">Guinea-Bissau (245)</option>
                        <option value="592">Guyana (592)</option>
                        <option value="509">Haiti (509)</option>
                        <option value="504">Honduras (504)</option>
                        <option value="852">Hong Kong (852)</option>
                        <option value="36">Hungary (36)</option>
                        <option value="354">Iceland (354)</option>
                        <option value="91" selected="">India (91)</option>
                        <option value="62">Indonesia (62)</option>
                        <option value="98">Iran (98)</option>
                        <option value="964">Iraq (964)</option>
                        <option value="353">Ireland (353)</option>
                        <option value="972">Israel (972)</option>
                        <option value="39">Italy (39)</option>
                        <option value="225">Ivory Coast (225)</option>
                        <option value="1876">Jamaica (1876)</option>
                        <option value="81">Japan (81)</option>
                        <option value="962">Jordan (962)</option>
                        <option value="7">Kazakhstan (7)</option>
                        <option value="254">Kenya (254)</option>
                        <option value="686">Kiribati (686)</option>
                        <option value="965">Kuwait (965)</option>
                        <option value="996">Kyrgyzstan (996)</option>
                        <option value="856">Laos (856)</option>
                        <option value="371">Latvia (371)</option>
                        <option value="961">Lebanon (961)</option>
                        <option value="266">Lesotho (266)</option>
                        <option value="231">Liberia (231)</option>
                        <option value="423">Liechtenstein (423)</option>
                        <option value="370">Lithuania (370)</option>
                        <option value="352">Luxembourg (352)</option>
                        <option value="389">North Macedonia (389)</option>
                        <option value="261">Madagascar (261)</option>
                        <option value="265">Malawi (265)</option>
                        <option value="60">Malaysia (60)</option>
                        <option value="960">Maldives (960)</option>
                        <option value="223">Mali (223)</option>
                        <option value="356">Malta (356)</option>
                        <option value="692">Marshall Islands (692)</option>
                        <option value="596">Martinique (596)</option>
                        <option value="222">Mauritania (222)</option>
                        <option value="230">Mauritius (230)</option>
                        <option value="262">Mayotte (262)</option>
                        <option value="52">Mexico (52)</option>
                        <option value="691">Micronesia, Federated States of (691)</option>
                        <option value="373">Moldova (373)</option>
                        <option value="377">Monaco (377)</option>
                        <option value="976">Mongolia (976)</option>
                        <option value="1664">Montserrat (1664)</option>
                        <option value="212">Morocco (212)</option>
                        <option value="258">Mozambique (258)</option>
                        <option value="95">Myanmar (95)</option>
                        <option value="264">Namibia (264)</option>
                        <option value="674">Nauru (674)</option>
                        <option value="977">Nepal (977)</option>
                        <option value="31">Netherlands (31)</option>
                        <option value="687">New Caledonia (687)</option>
                        <option value="64">New Zealand (64)</option>
                        <option value="505">Nicaragua (505)</option>
                        <option value="227">Niger (227)</option>
                        <option value="234">Nigeria (234)</option>
                        <option value="683">Niue (683)</option>
                        <option value="672">Norfolk Island (672)</option>
                        <option value="1670">Northern Mariana Islands (1670)</option>
                        <option value="47">Norway (47)</option>
                        <option value="968">Oman (968)</option>
                        <option value="92">Pakistan (92)</option>
                        <option value="680">Palau (680)</option>
                        <option value="507">Panama (507)</option>
                        <option value="675">Papua New Guinea (675)</option>
                        <option value="595">Paraguay (595)</option>
                        <option value="51">Peru (51)</option>
                        <option value="63">Philippines (63)</option>
                        <option value="48">Poland (48)</option>
                        <option value="351">Portugal (351)</option>
                        <option value="1939">Puerto Rico (1939)</option>
                        <option value="974">Qatar (974)</option>
                        <option value="262">Reunion (262)</option>
                        <option value="40">Romania (40)</option>
                        <option value="7">Russian Federation (7)</option>
                        <option value="250">Rwanda (250)</option>
                        <option value="290">Saint Helena (290)</option>
                        <option value="1869">Saint Kitts and Nevis (1869)</option>
                        <option value="1758">Saint Lucia (1758)</option>
                        <option value="508">Saint Pierre and Miquelon (508)</option>
                        <option value="1784">Saint Vincent and the Grenadines (1784)</option>
                        <option value="685">Samoa (685)</option>
                        <option value="378">San Marino (378)</option>
                        <option value="239">Sao Tome and Principe (239)</option>
                        <option value="966">Saudi Arabia (966)</option>
                        <option value="221">Senegal (221)</option>
                        <option value="248">Seychelles (248)</option>
                        <option value="232">Sierra Leone (232)</option>
                        <option value="65">Singapore (65)</option>
                        <option value="421">Slovakia (421)</option>
                        <option value="386">Slovenia (386)</option>
                        <option value="677">Solomon Islands (677)</option>
                        <option value="252">Somalia (252)</option>
                        <option value="27">South Africa (27)</option>
                        <option value="500">South Georgia and the South Sandwich Islands (500)</option>
                        <option value="211">South Sudan (211)</option>
                        <option value="34">Spain (34)</option>
                        <option value="249">Sudan (249)</option>
                        <option value="597">Suriname (597)</option>
                        <option value="268">Swaziland (268)</option>
                        <option value="46">Sweden (46)</option>
                        <option value="41">Switzerland (41)</option>
                        <option value="963">Syria (963)</option>
                        <option value="992">Tajikistan (992)</option>
                        <option value="255">Tanzania (255)</option>
                        <option value="66">Thailand (66)</option>
                        <option value="228">Togo (228)</option>
                        <option value="690">Tokelau (690)</option>
                        <option value="676">Tonga (676)</option>
                        <option value="1868">Trinidad and Tobago (1868)</option>
                        <option value="216">Tunisia (216)</option>
                        <option value="90">Turkey (90)</option>
                        <option value="993">Turkmenistan (993)</option>
                        <option value="1649">Turks and Caicos Islands (1649)</option>
                        <option value="688">Tuvalu (688)</option>
                        <option value="256">Uganda (256)</option>
                        <option value="380">Ukraine (380)</option>
                        <option value="971">United Arab Emirates (971)</option>
                        <option value="44">United Kingdom (44)</option>
                        <option value="1">United States (1)</option>
                        <option value="598">Uruguay (598)</option>
                        <option value="998">Uzbekistan (998)</option>
                        <option value="678">Vanuatu (678)</option>
                        <option value="58">Venezuela (58)</option>
                        <option value="84">Vietnam (84)</option>
                        <option value="681">Wallis and Futuna (681)</option>
                        <option value="967">Yemen (967)</option>
                        <option value="260">Zambia (260)</option>
                        <option value="263">Zimbabwe (263)</option>
                    </select>
                    <div style={{maxWidth: "180px", height: "42px"}}>
                      <TextInput 
                        label="Mobile *"
                        name="send_mobile" 
                        type="number"
                        disabled={otpVerified}
                        value={formData.send_mobile} 
                        onChange={onChangeHandler}
                        icon={<PhoneIcon sx={{ color: styles["primary_color"]}}/>}
                        error={errors.send_mobile}
                        errorMsg={errors.send_mobile}
                        />
                    </div>
                   
                      <div className='flexCenter_Row' style={{ width: "100px", marginRight: "15px"}}>
                      {
                        otpVerified
                        ?
                        <div className='flexCenterCenterRow' style={{ fontWeight: "500", color: "#2e7d32", gap: "10px"}}>
                          <DoneIcon color="success"/>
                          Verified
                        </div>
                        :
                        sentOTP
                        ?
                        <LoadingBtn onClick={() => sendOTPhandler()} text={"Resend"} loading={loading} main={true} disabled={!resendOtp}/>
                        :
                        <LoadingBtn onClick={() => sendOTPhandler()} text={"Verify"} loading={loading} main={true}/>
                      }
                      </div>
                  </div>
                  {
                    sentOTP
                    &&
                    <div className='login__input flexCenterSBRow'>
                        <div style={{maxWidth: "225px"}} className='flexCenterSBRow'>
                          <TextInput 
                            label="OTP *"
                            type="" 
                            name="otp" 
                            value={otp} 
                            onChange={(e) => setOtp(e.target.value)}
                            icon={<PinIcon sx={{ color: styles["primary_color"]}}/>}
                            />
                          <div style={{maxWidth: "100px"}}>
                            <LoadingBtn onClick={() => handleVerifyOtp()} text={"Verify OTP"} loading={loading} main={true}/>
                          </div>
                        </div>
                        <div className='flexCenterCenterRow' style={{ width: "170px", marginRight: "15px", fontSize: "14px", fontWeight: "500", textAlign: "right"}}>
                          Resend OTP in: <Timer expiryTimestamp={new Date().setSeconds(new Date().getSeconds() + 60)} setResendOtp={setResendOtp}/>
                        </div>
                    </div>
                  }
                  
                  
                  <div className='login__input'>
                    <TextInput 
                      id="Email"
                      label="Work Email Id *"
                      type="email" 
                      name="send_email"  
                      value={formData.send_email} 
                      onChange={onChangeHandler}
                      placeHolder="Email"
                      icon={<EmailIcon sx={{ color: styles["primary_color"]}}/>}
                      error={errors.send_email}
                      errorMsg={errors.send_email}
                      />
                  </div>
                  <div className='flexCenter_Row' >
                    {
                      submitted
                      ?
                      <div style={{ fontWeight: "500", margin: "10px"}}>
                        We have received your request to create new account. You will receive an activation email shortly.
                      </div>  
                      :
                      <>
                        <LoadingBtn onClick={() => setPage(1)} text={"Previous"} loading={loading}/>
                        <Button1 onClick={onSubmitHandler} text={"Submit"} loading={loading} main={true} disabled={enableFinalSave}/>
                      </>
                    }
                  </div>
                </>
            }
            
          </div>
        </div>

      </div>
      <Footer />
    </>
  )
}

export default CompanySignUp;


const Timer = ({ expiryTimestamp, setResendOtp }) => {
  const {
    seconds,
    isRunning,
  } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });


  return (
    <div style={{textAlign: 'center'}}>
      <div>
        <span>{seconds}</span> seconds
      </div>
      {
        isRunning 
        ?
          setResendOtp(false)
        : 
          setResendOtp(true)
      }
    </div>
  );
}