import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import styles from "../../global.scss";


export const GetCities = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  var response; 
  try {
    const { data } = await api.getCities(formData);
    response = data
    if (data.status === "success") {
      dispatch({ type: actionTypes.GET_CITIES, payload: data.data.Records });
    }else{
      
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
  return response;
};

export const SendOTP = (number) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  var response; 
  try {
    const { data } = await api.sendOTP(number);
    response = data
    if (data.status === "success") {
      <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
        {/* <CheckIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/> */}
        {data.message}
      </div>
    }else{
      toast.error(
        <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
          {/* <ErrorOutlineRoundedIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/> */}
          {data.message}
        </div>
        )
    }
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
  }
  return response;
};

export const VerifyOTP = (token, otp, number) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  var response; 
  try {
    const { data } = await api.verifyOTP(token, otp, number);
    response = data
    if (data.status === "success") {
      <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
        {/* <CheckIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/> */}
        {data.message}
      </div>
    }else{
      toast.error(
        <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
          {/* <ErrorOutlineRoundedIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/> */}
          {data.message}
        </div>
        )
    }
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
  }
  return response;
};

export const RegisterCompany = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  var response; 
  try {
    const { data } = await api.companyRegister(formData);
    response = data
    if (data.status === "success") {
      <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
        {/* <CheckIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/> */}
        {data.message}
      </div>
    }else{
      toast.error(
        <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
          {/* <ErrorOutlineRoundedIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/> */}
          {data.message}
        </div>
        )
    } 
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
  return response;
};